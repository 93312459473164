import React from "react";

function About() {
	return (
		<div>
			<h1 className="text-6xl mb-4">Github Finder</h1>
			<p>
				Project built using React. Uses Github API to search for users
				and their repos
			</p>
		</div>
	);
}

export default About;
