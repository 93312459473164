import axios from "axios";
const GITHUB_URL = process.env.REACT_APP_GITHUB_URL;
const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN;

const github = axios.create({
	baseURL: GITHUB_URL,
	headers: { Authorization: `token ${GITHUB_TOKEN}` },
});

export const searchUsers = async (text) => {
	const params = new URLSearchParams({
		q: text,
	});

	const response = await axios.get(`${GITHUB_URL}/search/users?${params}`);

	return response.data.items;
};

export const getUserAndRepos = async (username) => {
	const [user, repos] = await Promise.all([
		axios.get(`${GITHUB_URL}/users/${username}`),
		axios.get(`${GITHUB_URL}/users/${username}/repos`),
	]);

	return { user: user.data, repos: repos.data };
};
